import {FC, useEffect, useState} from 'react';
import '../style/BrandDetail.scss';
import {useHistory, useParams} from "react-router-dom";
import _ from 'lodash';
import gfm from "remark-gfm";
import {IBrandsApi} from "../../Interface/IBrandsApi";

import noImage from '../../Assets/No_image.png';
import Button from "./Button";
import LoadingAnimation from "./LoadingAnimation";
import Markdown from 'react-markdown';

interface BrandDetailProps {
    parentUrl: string
}

const BrandDetail: FC<BrandDetailProps> = ({parentUrl}) => {
    const [content, setContent] = useState(<LoadingAnimation/>);
    const {machineName} = useParams<{ machineName: string }>();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const json: IBrandsApi = await fetch("/api/brands.json").then(res => res.json());
            const brand = json.find(brand => _.escape(_.lowerCase(brand.name)) === machineName);

            const image = brand && (
                <img src={brand.icon || noImage} alt={brand.name + " Logo"}
                     className={brand.special ? "iconBrandsTileSpecial" : "iconBrandsTile"}/>);
            const backButton = (
                <Button
                    onClick={() => history.push(parentUrl)}
                    children="Zurück"
                    style={{fontSize: "large", marginBottom: "1em"}}
                />
            );

            setContent(brand ?
                <>
                    <span className="center" children={backButton}/>
                    <span className="center"
                          children={brand.website ?
                              <a href={brand.website} target="_blank" rel="noreferrer" children={image}/> : image}/>
                    <span className="center-container-vmlskg">
                    <Markdown
                        className="text-container-hjrngire"
                        children={brand.description || brand.short_description || "Keine Beschreibung verfügbar!"}
                        plugins={[gfm]}
                    />
                    </span>
                </>
                :
                <span className="not-found-nbjodnb">
                    <span className="center" children={backButton}/>
                    <span className="center" children="Diese Marke existiert nicht!"/>
                </span>
            );
        })();
    }, [machineName, parentUrl]);

    return (
        <div className="brand-detail-nbjlrw" children={content}/>
    );
};
export default BrandDetail;
