import {FC} from 'react';
import '../style/CategoryList.scss';
import {setLocation, useQuery} from '../../../Util/util';
import {useRouteMatch} from 'react-router-dom';
import {MachinesApiCategory} from '../../../Interface/IMachinesApi';
import {ButtonStyle} from './ButtonStyle';
import _ from 'lodash';

import Button from '../../../Components/react/Button';
import LinkButton from '../../../Components/react/LinkButton';

interface CategoryListProps {
    categories: Array<MachinesApiCategory>
}

const CategoryList: FC<CategoryListProps> = ({categories}) => {
    const {path} = useRouteMatch();
    const query = useQuery();
    const queryPaths = query.get("path")?.length ? query.get("path")?.split("/") : null;
    const queryPaths2 = query.get("path")?.split("/");
    queryPaths2?.pop();
    const newQuery = queryPaths2?.join("/");
    const backLink = path + (newQuery ? `?path=${newQuery}` : "");

    return (
        <div className="category-list-nblrnob">
            <span className="center">
                <Button onClick={() => setLocation(queryPaths ? backLink : "/")} children="Zurück" style={ButtonStyle}/>
            </span>
            <span className="center">
                <ul
                    children={categories.map((category, i) => (
                        <li key={_.uniqueId()} style={{marginLeft: i * 5}}>
                            <LinkButton
                                children={category.category || category.name}
                                onClick={() => setLocation(
                                    path +
                                    "?path=" +
                                    (
                                        queryPaths ?
                                            queryPaths.join("/") + `/${_.escape(_.lowerCase(category.category))}`
                                            :
                                            `${_.escape(_.lowerCase(category.category))}`
                                    )
                                )}
                                style={{fontSize: "larger"}}
                            />
                        </li>
                    ))}/>
            </span>
        </div>
    );
};
export default CategoryList;
