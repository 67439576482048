import {CSSProperties, FC, MouseEventHandler, ReactNode} from 'react';
import '../style/LinkButton.scss';

interface LinkProps {
    children: ReactNode
    onClick: MouseEventHandler
    style?: CSSProperties
}

const LinkButton: FC<LinkProps> = ({children, onClick, style}) => {
    return (
        <button style={style} onClick={onClick} className="link" children={children}/>
    );
};

export default LinkButton;
