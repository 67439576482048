import {FC, useEffect, useState} from 'react';
import '../style/MaschinenAnlagen.scss';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import {IMachinesApi} from '../../../Interface/IMachinesApi';
import _ from 'lodash';
import {useQuery} from '../../../Util/util';

import LoadingAnimation from '../../../Components/react/LoadingAnimation';
import CategoryList from './CategoryList';
import MachineRenderer from './MachineRenderer';

const key = "machines";

const MaschinenAnlagen: FC = () => {
    const [content, setContent] = useState(<LoadingAnimation/>);
    const paths = useQuery().get("path")?.split("/") || null;

    useEffect(() => {
        (async () => {
            const cache = !!sessionStorage.getItem(key);

            const json: IMachinesApi = cache ?
                JSON.parse(sessionStorage.getItem(key) as string) :
                await fetch("/api/machines.json").then(res => res.json());
            !cache && sessionStorage.setItem(key, JSON.stringify(json));
            const machines = json.find(obj => obj.brand === "Hahn");

            if (!machines) {
                setContent(<span
                    className="maschinen-anlagen-nothing-found-vnjisadngi"
                    children='Es wurde nichts für die Marke "Hahn" gefunden!'
                />);
                return;
            }

            if (!paths || !paths[0]) {
                setContent(<CategoryList categories={machines.categories}/>);
                return;
            }

            let pointer = machines.categories.find(category => _.escape(_.lowerCase(category.name || category.category)) === paths[0]);
            paths.forEach((path, i) => {
                if (i === 0)
                    return;

                if (!pointer)
                    return;

                // @ts-ignore
                pointer = pointer.machines.find(obj => _.escape(_.lowerCase(obj.name || obj.category)) === path);
            });

            if (pointer)
                if ("category" in pointer.machines[0])
                    //@ts-ignore
                    setContent(<CategoryList categories={pointer.machines}/>);
                else
                    //@ts-ignore
                    setContent(<MachineRenderer machines={pointer.machines}/>);
        })();
    }, []);

    return (
        <div className="maschinen-anlagen-bcjabgfu">
            <span className="center" style={{fontSize: "xxx-large"}} children="Maschinen & Anlagen"/>
            <span className="maschinen-anlagen-container-ndjsiauognr" children={content}/>
        </div>
    );
};
export default MaschinenAnlagen;
