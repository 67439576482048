import {FC} from 'react';
import '../style/Kontakt.scss';

import map from '../../../Assets/map.png';
import {FaEnvelopeSquare, FaFax, FaPhone} from 'react-icons/fa';


const Kontakt: FC = () => {
    return (
        <div className="kontakt">
            <span className="center" style={{fontSize: "xxx-large"}}>
                Ing. Walter Hahn Bäckerei-& Konditoreitechnik
            </span>
            <br/>
            <span className="center">
                Telefon <FaPhone size="0.75em"/>: <a href="tel:+43 (0) 2742/352482-0">+43 (0) 2742/352482-0</a> oder <a
                href="tel:+43 (0) 2742/352480-0">+43 (0) 2742/352480-0</a>
            </span>
            <span className="center">
                Fax <FaFax size="0.75em"/>: <a href="fax:+43 (0) 2742/352480-12">+43 (0) 2742/352480-12</a>
            </span>
            <span className="center">
                E-Mail <FaEnvelopeSquare size="0.75em"/>: <a
                href="mailto:ing.walter.hahn@aon.at">ing.walter.hahn@aon.at </a> oder <a
                href="mailto:hahn.technik@aon.at"> hahn.technik@aon.at</a>
            </span>
            <br/>
            <span className="center">
                <iframe title="map" width="640" height="480" frameBorder="0" scrolling="no" marginHeight={0}
                        marginWidth={0} sandbox="allow-scripts"
                        src="https://www.openstreetmap.org/export/embed.html?bbox=15.622786581516268%2C48.20270492564501%2C15.62546879053116%2C48.20406354822785&amp;layer=mapnik&amp;marker=48.20338424144094%2C15.624227686023712"
                        style={{borderRadius: "1em"}}>
                    <img width="640" height="480" src={map} alt="Karte vom Standort"/>
                </iframe>
            </span>
        </div>
    );
}
export default Kontakt;
