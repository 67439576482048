/*
 * TODO:
 * Service erweitern
 * Über uns erweitern
 */

import {CSSProperties, FC} from 'react';
import './App.scss';

import {FaClock} from 'react-icons/fa';
import {Link, Route, Switch, useHistory} from 'react-router-dom';
import Navbar from './Components/react/Navbar';
import Button from './Components/react/Button';
import LinkButton from "./Components/react/LinkButton";
import Footer from './Components/react/Footer';
import Home from './Sites/Home/react/Home';
import Kontakt from './Sites/Kontakt/react/Kontakt';
import Impressum from './Sites/Impressum/react/Impressum';
import Service from './Sites/Service/react/Service';
import UeberUns from './Sites/Über uns/react/UeberUns';
import Gebrauchtmaschinen from './Sites/Gebrauchtmaschinen/react/Gebrauchtmaschinen';
import Marken from './Sites/Marken/react/Marken';
import MaschinenAnlagen from './Sites/Maschinen & Anlagen/react/MaschinenAnlagen';
import _404 from './Sites/404/react/404';

import icon from './Assets/hahnlogo.gif';


const ButtonStyle: CSSProperties = {
    backgroundColor: "inherit",
    border: "none",
    color: "var(--accent1)",
    fontSize: "larger",
};

const LinkStyle: CSSProperties = {
    fontSize: "0.9em"
};


const App: FC = () => {
    const history = useHistory();

    return (
        <div className="wrapper" onContextMenu={e => e.preventDefault()}>
            <section className="content-primary">
                <section className="center" style={{width: "95%"}}>
                    <Navbar>
                        <Link to="/" style={{display: "contents"}}>
                            <img src={icon}
                                 alt="Hahn Logo"
                                 className="logo"
                                 tabIndex={0}
                                 width="128"
                            />
                        </Link>
                        <div style={{display: "flex", flexFlow: "row-reverse", marginTop: "-25px"}}>
                            <Button onClick={() => history.push("/gebrauchtmaschinen")} style={ButtonStyle}
                                    children="GEBRAUCHTMASCHINEN"/>
                            <Button onClick={() => history.push("/marken")} style={ButtonStyle} children="MARKEN"/>
                            <Button onClick={() => history.push("/service")} style={ButtonStyle} children="SERVICE"/>
                            <Button onClick={() => history.push("/ueber_uns")} style={ButtonStyle} children="ÜBER UNS"/>
                        </div>
                    </Navbar>
                    <br style={{marginBottom: "5em"}}/>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route path="/ueber_uns" component={UeberUns}/>
                        <Route path="/service" component={Service}/>
                        <Route path="/gebrauchtmaschinen" component={Gebrauchtmaschinen}/>
                        <Route path="/impressum" component={Impressum}/>
                        <Route path="/kontakt" component={Kontakt}/>
                        <Route path="/maschinen_anlagen" component={MaschinenAnlagen}/>
                        <Route path="/marken" component={Marken}/>

                        {/* "404"-Route */}
                        <Route path="*" component={_404}/>
                    </Switch>
                </section>
            </section>
            <section className="content-secondary">
                <Footer>
                    <div className="gridFooterWrapper">
                        <li>
                            <h1>Allgemeines</h1>
                            <LinkButton onClick={() => history.push("/impressum")} style={LinkStyle}
                                        children="Impressum"/>
                            <br/>
                            <LinkButton onClick={() => history.push("/kontakt")} style={LinkStyle} children="Kontakt"/>
                            <br style={{marginBottom: "1em"}}/>
                            <span>
                            <FaClock size="0.75em"/>
                            <span style={{marginLeft: 6}}>Öffnungszeiten:</span><br/>
                            <span style={{marginLeft: "1em"}}>Mo-Do: <time children="7:15-16:30"/></span><br/>
                            <span style={{marginLeft: "1em"}}>Fr: <time children="7:15-12:00"/></span>
                        </span>
                        </li>
                        <li>
                            <h1>Kontakt</h1>
                            <span>Ing. Walter Hahn</span><br/>
                            <span>Bäckerei - & Konditoreitechnik</span><br/>
                            <span>GmbH & Co KG</span><br/>
                            <span>Schreinergasse 10</span><br/>
                            <span>A-3100 St. Pölten</span><br/>
                        </li>
                    </div>
                </Footer>
            </section>
        </div>
    );
};

export default App;
