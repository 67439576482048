import {FC} from 'react';
import '../style/MachineRenderer.scss';
import {MachinesApiMachine} from '../../../Interface/IMachinesApi';
import gfm from 'remark-gfm';
import _ from 'lodash';
import {ButtonStyle} from './ButtonStyle';
import {useRouteMatch} from 'react-router';
import {setLocation, useQuery} from '../../../Util/util';

import no_image from '../../../Assets/No_image.png';
import Markdown from 'react-markdown';
import ImageZoom from 'react-inner-image-zoom';
import Button from '../../../Components/react/Button';


interface MachineRendererProps {
    machines: Array<MachinesApiMachine>
}

const MachineRenderer: FC<MachineRendererProps> = ({machines}) => {
    const {path} = useRouteMatch();
    const query = useQuery().get("path")?.split("/");
    query?.pop();
    const newQuery = query?.join("/");
    const backLink = path + (newQuery ? `?path=${newQuery}` : "");

    return (
        <div className="machine-renderer-nbjlfsa">
            <span className="center">
                <Button onClick={() => setLocation(backLink)} children="Zurück" style={ButtonStyle}/>
            </span>
            {
                machines.map(({name, description, images}) => (
                    <div className="machine-renderer-wrapper-nbjlb" id={_.escape(_.lowerCase(name))} key={_.uniqueId()}>
                <span
                    className="machine-renderer-image-container-bnjsb"
                    children={
                        Array.isArray(images) ?
                            images.map(image => (
                                <ImageZoom
                                    width={256}
                                    height={192}
                                    zoomType="hover"
                                    zoomPreload={true}
                                    src={image || no_image}
                                    alt={name + " Bild"}
                                    hideHint={true}
                                    hideCloseButton={true}
                                    fadeDuration={250}
                                    fullscreenOnMobile={true}
                                    className="machine-renderer-image-nbidnu"
                                    key={_.uniqueId()}
                                />
                            )) : (
                                <ImageZoom
                                    width={256}
                                    height={192}
                                    zoomType="hover"
                                    zoomPreload={true}
                                    src={images || no_image}
                                    alt={name + " Bild"}
                                    hideHint={true}
                                    hideCloseButton={true}
                                    fadeDuration={250}
                                    fullscreenOnMobile={true}
                                    className="machine-renderer-image-nbidnu"
                                />
                            )
                    }/>
                        <span>
                            <span children={name} className="machine-renderer-title-bnosanbo"/>
                            <Markdown children={description} plugins={[gfm]} disallowedElements={["script", "img"]}/>
                        </span>
                    </div>
                ))
            }
        </div>
    );
};
export default MachineRenderer;
