import {CSSProperties, FC, MouseEventHandler, ReactNode} from 'react';
import '../style/Button.scss';

interface ButtonProps {
    children: ReactNode
    onClick: MouseEventHandler
    onHover?: MouseEventHandler
    style?: CSSProperties
}

const Button: FC<ButtonProps> = ({children, onHover, onClick, style}) => {
    return (
        <button className="button" style={style} onClick={onClick} onMouseEnter={onHover} children={children}/>
    );
};
export default Button;
