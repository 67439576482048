import {FC, ReactNode} from 'react';
import '../style/Navbar.scss';

interface NavbarProps {
    children: ReactNode
}

const Navbar: FC<NavbarProps> = ({children}) => {
    return (
        <nav className="navbar" children={children}/>
    );
}

export default Navbar;
