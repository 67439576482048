import {FC, ReactNode, useEffect, useState} from 'react';
import '../style/Mitarbeiter.scss';
import {IPublicEmployeesApi} from '../../../Interface/IPublicEmployeesApi';
import {useHistory, useRouteMatch} from 'react-router-dom';
import _ from 'lodash';

import Tile from './Tile';
import LoadingAnimation from '../../../Components/react/LoadingAnimation';
import Button from '../../../Components/react/Button';

const key = "employees";

const Über_uns: FC = () => {
    const [content, setContent] = useState<ReactNode>(<LoadingAnimation/>);
    const {path} = useRouteMatch();
    const history = useHistory();
    const pathComponents = path.split("/");
    pathComponents.pop();
    const backLink = pathComponents.join("/");

    useEffect(() => {
        (async () => {
            const cache = !!sessionStorage.getItem(key);

            const json: IPublicEmployeesApi = cache ?
                JSON.parse(sessionStorage.getItem(key) as string) :
                await fetch("/api/public_employees.json").then(res => res.json());
            !cache && sessionStorage.setItem(key, JSON.stringify(json));

            setContent(
                <section className="mitarbeiter-grid-wrapper-cbhaFWJTR" children={
                    json.map(({title, text, image}) =>
                        <Tile title={title} image={image} children={text} key={_.uniqueId()}/>
                    )
                }/>
            );
        })();
    }, []);

    return (
        <div className="mitarbeiter-hjofnsaon">
            <span className="center" style={{fontSize: "xxx-large"}}>Über uns</span>
            <span className="center">
                <Button
                    style={{fontSize: "large"}}
                    onClick={() => history.push(backLink)}
                    children="Zurück"
                />
            </span>
            <br/>
            <span className="center" children={content}/>
        </div>
    );
};
export default Über_uns;
