import {CSSProperties, FC, ReactNode} from 'react';
import '../style/Footer.scss';

interface FooterProps {
    children: ReactNode
    style?: CSSProperties
}

const Footer: FC<FooterProps> = ({children, style}) => {
    return (
        <div className="footerWrapper">
            <footer className="footer" style={style} children={children}/>
        </div>
    );
}

export default Footer;
