import {FC} from 'react';
import {useHistory} from "react-router-dom";

import icon from '../../../Assets/hahnlogo.gif';
import Button from '../../../Components/react/Button';


const _404: FC = () => {
    const history = useHistory();

    return (
        <div>
        <span className="center">
            <Button style={{fontSize: "larger"}} onClick={() => history.push("/")} children="Zurück"/>
        </span>
            <span className="center" style={{fontSize: "xxx-large"}} children="404 Not Found"/>
            <span className="center" style={{fontSize: "x-large", color: "darkslategray"}}
                  children="Sie sehen diese Seite, weil es unter diesem Pfad keinen Inhalt gibt!"/>
            <br/>
            <svg className="center" width="256" height="256">
                <rect
                    x="1"
                    y="1"
                    rx="1em"
                    ry="1em"
                    width="254"
                    height="254"
                    fill="whitesmoke"
                    stroke="black"
                    strokeWidth="2px"
                />
                <circle cx="50" cy="40" r="15"/>
                <circle cx="206" cy="40" r="15"/>
                <foreignObject width="128" height="128" x="64" y="64">
                    <img src={icon} alt="Hahn Logo" width="128"/>
                </foreignObject>
                <path
                    d="M10,250 C50,150 206,150 246,250"
                    fill="transparent"
                    stroke="black"
                    strokeWidth="3px"
                />
            </svg>
        </div>
    );
};

export default _404;
