import {FC, MouseEvent, useState} from 'react';
import '../style/Impressum.scss'

import {FaCopyright, FaEnvelopeSquare, FaExternalLinkAlt, FaFax, FaPhone} from 'react-icons/fa';
import {SiNginx, SiReact, SiSass, SiTypescript} from 'react-icons/si';

const Impressum: FC = () => {
    const [easterEgg, setEasterEgg] = useState(false);
    const easterEggListener = ({altKey, ctrlKey, shiftKey}: MouseEvent) => {
        if (!altKey || !ctrlKey || !shiftKey)
            return;
        setEasterEgg(!easterEgg);
    }

    return (
        <div className="impressum">
            <section id="impressum">
                <h1 className="center">
                    Impressum
                </h1>
                <span className="center lightBold">
              Ing. Walter Hahn Bäckerei- und Konditoreitechnik GmbH & Co KG
          </span>
                <span className="center">
              Schreinergasse 10
          </span>
                <span className="center">
              3100 St.Pölten
          </span>
                <br/>
                <span className="center">
              <span className="lightBold">Telefon</span> <FaPhone size="0.75em"/>: <a href="tel:+43 (0) 2742/352482-0">+43 (0) 2742/352482-0</a> oder <a
                    href="tel:+43 (0) 2742/352480-0">+43 (0) 2742/352480-0</a>
          </span>
                <span className="center">
              Fax <FaFax size="0.75em"/>: <a href="fax:+43 (0) 2742/352480-12">+43 (0) 2742/352480-12</a>
          </span>
                <span className="center">
              E-Mail <FaEnvelopeSquare size="0.75em"/>: <a
                    href="mailto:ing.walter.hahn@aon.at">ing.walter.hahn@aon.at </a> oder <a
                    href="mailto:hahn.technik@aon.at"> hahn.technik@aon.at</a>
          </span>
                <br/>
                <span className="center">
              <span className="lightBold">UID-Nummer</span>: ATU19848002
          </span>
                <span className="center">
              <span className="lightBold">Firmenbuchnummer</span>: 110726h
          </span>
                <span className="center">
              <span className="lightBold">Firmengericht</span>: Landesgericht St.Pölten
          </span>
                <br/>
                <span className="center lightBold">
              Weitere Infos finden sie <a
                    href="https://www.wkoecg.at/Web/Ecg.aspx?FirmaID=0ddfbaea-d49b-41e2-95fd-c579ffb5b186"
                    target="_blank"
                    rel="noreferrer">hier <FaExternalLinkAlt size="0.75em"/></a>
          </span>
            </section>
            <hr/>
            <section id="haftungsausschluss">
                <h1
                    className="center"
                    children="Haftungsausschluss"
                />
                <h2
                    className="center"
                    children="Haftung für Inhalte dieser Webseite"
                />
                <article className="center">
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                </article>
                <br/>
                <h2
                    className="center"
                    children="Haftung für Links auf Webseiten Dritter"
                />
                <article className="center">
                    Unser Angebot enthält Links zu externen Websites. Auf den Inhalt dieser externen Webseiten haben wir keinerlei Einfluss. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                </article>
                <br/>
                <h2
                    className="center"
                    children="Urheberrecht"
                />
                <article className="center">
                    Die Betreiber dieser Webseite sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen. Die durch die Seitenbetreiber erstellten Inhalte und Werke auf dieser Webseite unterliegen dem Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                </article>
                <br/>
                <h2
                    className="center"
                    children="Datenschutzerklärung und persönliche Daten"
                />
                <article className="center">
                    Mit Ausnahme der IP-Adresse und der Uhrzeit des Zugriffs speichern wir keinerlei Informationen über Besucher unserer Seite.

                    Diese Seite macht weder Gebrauch von Cookies, noch diverser Analyticsdienste.
                </article>
                <br/>
            </section>
            <hr/>
            <section>
                <span className="center" onDoubleClick={easterEggListener}>
              <FaCopyright size="0.75em"/> 2021 Hahn Bäckerei- und Konditoreitechnik GmbH & Co KG. Alle Rechte verbehalten.
          </span>
                {
                    easterEgg &&
                    <span className="center">
                    Site designed and built by <span className="lightBold">GraF</span> with <SiReact
                        style={{backgroundColor: "black", color: "#61dbfb", borderRadius: "1em", padding: 1}}/>, <SiSass
                        style={{
                            backgroundColor: "black",
                            color: "#bf608c",
                            borderRadius: "1em",
                            padding: 1
                        }}/> and <SiTypescript style={{
                        backgroundColor: "black",
                        color: "#1169ca",
                        borderRadius: "2px",
                        padding: 1
                    }}/>; served to you by <SiNginx
                        style={{backgroundColor: "black", color: "green", borderRadius: "1em", padding: 1}}/>
                </span>
                }
            </section>
            <br/>
        </div>
    );
};
export default Impressum;
