import {FC} from 'react';
import '../style/Service.scss';

import {FaEnvelopeSquare, FaFax, FaPhone} from 'react-icons/fa';

const Service: FC = () => (
    <div className="service">
        <span style={{fontSize: "xxx-large"}} children="Reparatur & Service"/>
        <ul style={{fontSize: "larger"}}>
            <li children="Wir reparieren und servicieren alle Maschinen unserer Partnerfirmen und natürlich unsere eigenen Produkte."/>
            <li children="Wir haben die gängigsten Ersatzteile stets auf Lager, um Ausfallzeiten so gering wie möglich zu halten."/>
            <li children="Ersatzteil- und Zubehörverkauf."/>
            <li children="Generalüberholung von Gebrauchtmaschinen."/>
        </ul>
        <span style={{fontSize: "xx-large"}} children="Für weitere Informationen wenden sie sich an:"/>
        <br/>
        <span>
                Telefon <FaPhone size="0.75em"/>: <a href="tel:+43 (0) 2742/352482-0">+43 (0) 2742/352482-0</a> oder <a
            href="tel:+43 (0) 2742/352480-0">+43 (0) 2742/352480-0</a>
            </span>
        <br/>
        <span>
                Fax <FaFax size="0.75em"/>: <a href="fax:+43 (0) 2742/352480-12">+43 (0) 2742/352480-12</a>
            </span>
        <br/>
        <span>
                E-Mail <FaEnvelopeSquare size="0.75em"/>: <a
            href="mailto:ing.walter.hahn@aon.at">ing.walter.hahn@aon.at </a> oder <a
            href="mailto:hahn.technik@aon.at"> hahn.technik@aon.at</a>
            </span>

    </div>
);

export default Service;
