import {FC, ReactNode} from 'react';
import '../style/Tile.scss';

interface TileProps {
    children: ReactNode
    title: string,
    image: string
}

const Tile: FC<TileProps> = ({title, children, image}) => {
    return (
        <div className="tile-bnfenbjoe">
            <img src={image} alt={title + " Bild"} className="tile-image-vjksvisa"/>
            <span className="tile-text-wrapper-nbksao">
                <span className="tile-title-nklghaxy" children={title}/>
                <br/>
                <span className="tile-text-nmvcmw32" children={children}/>
            </span>
        </div>
    );
}
export default Tile;
