import {CSSProperties, FC, ReactNode, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {IBrandsApi} from "../../Interface/IBrandsApi";
import _ from "lodash";
import {useRouteMatch} from "react-router";

import LoadingAnimation from "./LoadingAnimation";
import Tooltip from 'react-tooltip';

interface BrandGridProps {
    details?: boolean
}

const LinkStyle: CSSProperties = {
    display: "contents"
};

const key = "brands";

const BrandGrid: FC<BrandGridProps> = ({details}) => {
    const [tiles, setTiles] = useState<ReactNode>(<LoadingAnimation/>);
    const {url} = useRouteMatch();

    useEffect(() => {
        (async () => {
            const cache = !!sessionStorage.getItem(key);

            const json: IBrandsApi = cache ?
                JSON.parse(sessionStorage.getItem(key) as string) :
                await fetch("/api/brands.json").then(res => res.json());
            !cache && sessionStorage.setItem(key, JSON.stringify(json));

            setTiles(json.map(brand =>
                <div style={{display: "contents"}} key={_.uniqueId()}>
                    <span>
                    {
                        brand.icon && (
                            brand.website ?
                                (
                                    <>
                                        {
                                            details ?
                                                <Link
                                                    style={LinkStyle}
                                                    to={url + "/" + _.escape(_.lowerCase(brand.name))}
                                                >
                                                    <img
                                                        className={brand.special ? "iconBrandsTileSpecial" : "iconBrandsTile"}
                                                        data-tip={
                                                            brand.short_description ||
                                                            (brand.description && brand.description.substr(0, 125) + "...")
                                                            || "Keine Beschreibung verfügbar!"
                                                        }
                                                        src={brand.icon} alt={brand.name + " Icon"}/>
                                                </Link> :
                                                <a href={brand.website} target="_blank" rel="noreferrer" style={LinkStyle}>
                                                    <img
                                                        className={brand.special ? "iconBrandsTileSpecial" : "iconBrandsTile"}
                                                        data-tip={
                                                            brand.short_description ||
                                                            (brand.description && brand.description.substr(0, 125) + "...")
                                                            || "Keine Beschreibung verfügbar!"
                                                        }
                                                        src={brand.icon} alt={brand.name + " Icon"}/>
                                                </a>
                                        }
                                        {(details && <Tooltip/>)}
                                    </>
                                ) :
                                <>
                                    {_.upperCase(brand.name) !== _.upperCase("Hahn") ?
                                        (
                                            details ?
                                                (<Link to={url + "/" + _.escape(brand.name)} style={LinkStyle}>
                                                    <img
                                                        className={brand.special ? "iconBrandsTileSpecial" : "iconBrandsTile"}
                                                        data-tip={brand.short_description}
                                                        src={brand.icon}
                                                        alt={brand.name + " Icon"}
                                                    />
                                                </Link>) :
                                                (
                                                    <img
                                                        className={brand.special ? "iconBrandsTileSpecial" : "iconBrandsTile"}
                                                        data-tip={brand.short_description}
                                                        src={brand.icon}
                                                        alt={brand.name + " Icon"}
                                                    />
                                                )
                                        ) :
                                        (// Special case for Hahn-button
                                            <Link to="/maschinen_anlagen" style={LinkStyle}>
                                                <img
                                                    className={brand.special ? "iconBrandsTileSpecial" : "iconBrandsTile"}
                                                    data-tip={brand.short_description}
                                                    src={brand.icon} alt={brand.name + " Icon"}/>
                                            </Link>
                                        )}
                                    {(details && <Tooltip/>)}
                                </>
                        )
                    }
                </span>
                    {brand.newline && <div style={{flexBasis: "100%"}}/>}
                </div>
            ));
        })();
    }, [details, url]);

    return (
        <span className="center" style={{display: "flex", width: "55em", flexWrap: "wrap", justifyContent: "center"}}
              children={tiles}/>
    );
}
export default BrandGrid;
