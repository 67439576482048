import {CSSProperties, FC} from 'react';
import '../style/UeberUns.scss';
import _ from 'lodash';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';

import LinkButton from '../../../Components/react/LinkButton';
import Mitarbeiter from './Mitarbeiter';
import Firmenchronik from './Firmenchronik';

const LinkStyle: CSSProperties = {
    fontSize: "large"
};

const UeberUns: FC = () => {
    const {path} = useRouteMatch();
    const history = useHistory();

    return (
        <>
            <Route exact path={path}>
                <div className="ueber_uns-vnjosnbds">
                    <span className="center" children="Über uns" style={{fontSize: "xxx-large"}}/>
                    <br/>
                    <ul
                        className="center"
                        children={
                            [
                                <LinkButton children="Mitarbeiter" onClick={() => history.push(path + "/mitarbeiter")}
                                            style={LinkStyle}/>,
                                <LinkButton children="Firmenchronik"
                                            onClick={() => history.push(path + "/firmenchronik")} style={LinkStyle}/>
                            ].map((item, i) => (
                                <li style={{marginLeft: 5 * i}} children={item} key={_.uniqueId()}/>
                            ))
                        }
                    />
                </div>
            </Route>
            <Route path={path + "/mitarbeiter"} children={<Mitarbeiter/>}/>
            <Route path={path + "/firmenchronik"} children={<Firmenchronik/>}/>
        </>
    );
};
export default UeberUns;
