import {FC} from 'react';
import '../style/Home.scss';

import Tile from './Tile';
import BrandGrid from '../../../Components/react/BrandGrid';

const Home: FC = () => {
    return (
        <div className="home">
            <span className="center" style={{fontSize: "xxx-large"}}>
                HAHN Bäckerei- & Konditoreitechnik
            </span>
            <section className="gridHomeWrapper">
                <Tile title="Über uns" to="/ueber_uns">
                    Hier gibt es näheres über uns und unsere Geschichte
                </Tile>
                <Tile title="Service & Kontakt" to="/service">
                    Im Service- und Kontaktbereich finden sie nötige Informationen, um uns zu kontaktieren
                </Tile>
                <Tile title="Gebrauchtmaschinen" to="gebrauchtmaschinen">
                    Hier finden sie Information zu Gebrauchtmaschinen aus dem Angebot
                </Tile>
            </section>
            <br style={{marginBottom: "10em"}}/>
            <span className="center" style={{fontSize: "xxx-large"}}
                  children="Unsere Maschinen machen es Ihnen leicht!"/>
            <BrandGrid/>
        </div>
    );
};
export default Home;
