import {FC, ReactNode} from 'react';
import '../style/Tile.scss'

import {Link} from 'react-router-dom';

interface TileProps {
    title: string
    children: ReactNode,
    to: string
}

const Tile: FC<TileProps> = ({title, children, to}) => {
    const tile = (
        <>
            <span className="title" children={title}/>
            <br/>
            <span className="childs" children={children}/>
        </>
    );

    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return (<li className="tile">{to ? (<Link to={to} className="button" children={tile}/>) : tile}</li>);
};
export default Tile;
