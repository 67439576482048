import {FC} from 'react';
import '../../../_constants.scss';
import Loader from "react-loader-spinner";

const LoadingAnimation: FC = () => {
    return (
        <span className="center">
            <Loader type="TailSpin" color="blue"/>
            <br style={{marginBottom: "4em"}}/>
        </span>
    );
};
export default LoadingAnimation;
