import {FC, ReactNode, useEffect, useState} from 'react';
import '../style/Firmenchronik.scss';
import {ICompanyHistoryApi} from '../../../Interface/ICompanyHistoryApi';
import {useHistory, useRouteMatch} from 'react-router-dom';
import gfm from 'remark-gfm';

import LoadingAnimation from '../../../Components/react/LoadingAnimation';
import Button from '../../../Components/react/Button';
import Markdown from 'react-markdown';

const key = "company_history";

const Firmenchronik: FC = () => {
    const history = useHistory();
    const [content, setContent] = useState<ReactNode>(<LoadingAnimation/>);
    const {path} = useRouteMatch();
    const pathComponents = path.split("/");
    pathComponents.pop();
    const backLink = pathComponents.join("/");

    useEffect(() => {
        (async () => {
            const cache = !!sessionStorage.getItem(key);

            const json: ICompanyHistoryApi = cache ?
                JSON.parse(sessionStorage.getItem(key) as string) :
                await fetch("/api/company_history.json").then(res => res.json());
            !cache && sessionStorage.setItem(key, JSON.stringify(json));

            const {description} = json;

            setContent(
                <Markdown
                    children={description}
                    className="firmenchronik-text-cbdahjg"
                    plugins={[gfm]}
                />
            );
        })();
    }, []);

    return (
        <div className="firmenchronik-njbdsnul">
            <div className="firmenchronik-wrapper-nbjfds">
                <span className="center" children="Firmenchronik" style={{fontSize: "xxx-large"}}/>
                <span className="center">
                    <Button onClick={() => history.push(backLink)} children="Zurück" style={{fontSize: "larger"}}/>
                </span>
                <br/>
                {content}
            </div>
        </div>
    );
};
export default Firmenchronik;
