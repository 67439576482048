import {FC, ReactNode, useEffect, useState} from 'react';
import '../style/Gebrauchtmaschinen.scss';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import _ from 'lodash';
import gfm from 'remark-gfm';
import {IUsedMachinesApi} from "../../../Interface/IUsedMachinesApi";

import no_image from '../../../Assets/No_image.png';
import LoadingAnimation from "../../../Components/react/LoadingAnimation";
import ImageZoom from 'react-inner-image-zoom';
import Markdown from 'react-markdown';

const key = "used_machines";

const Gebrauchtmaschinen: FC = () => {
    const [content, setContent] = useState<ReactNode>(<LoadingAnimation/>);

    useEffect(() => {
        (async () => {
            const cache = !!sessionStorage.getItem(key);

            const json: IUsedMachinesApi = cache ?
                JSON.parse(sessionStorage.getItem(key) as string) :
                await fetch("/api/used_machines.json").then(res => res.json());
            !cache && sessionStorage.setItem(key, JSON.stringify(json));

            setContent(<div className="gebrauchtmaschinen-container-nblfejnqep" children={
                    json.map((usedMachine, i) => (
                        <div className="gebrauchtmaschine-mksamag" key={_.uniqueId(i.toString())}>
                    <span className="gebrauchmaschine-image-container-vnjkdsf">
                        <ImageZoom
                            width={256}
                            height={192}
                            zoomType="hover"
                            zoomPreload={true}
                            src={usedMachine.icon || no_image}
                            alt={usedMachine.name + " Bild"}
                            hideHint={true}
                            hideCloseButton={true}
                            fadeDuration={250}
                            fullscreenOnMobile={true}
                            className="gebrauchtmaschine-image-vmkfdob"
                        />
                    </span>
                            <span className="gebrauchtmaschine-text-container-grngopnqg">
                        <span id={_.escape(_.lowerCase(usedMachine.name))}
                              className="gebrauchtmaschine-title-vcgsasahvf" children={usedMachine.name}/>
                        <Markdown
                            className="display-linebreaks"
                            disallowedElements={["script", "img"]}
                            remarkPlugins={[gfm,]}
                            children={usedMachine.description}
                        />
                    </span>
                        </div>
                    ))}
                />
            );
        })();
    }, []);

    return (
        <div className="gebrauchtmaschinen-bnjdfssngnf">
            <span className="center" style={{fontSize: "xxx-large"}}>Gebrauchtmaschinen</span>
            <span children={content}/>
        </div>
    );
}
export default Gebrauchtmaschinen;
