import {FC} from 'react';
import '../style/Marken.scss';

import {Route, useRouteMatch} from 'react-router';

import BrandGrid from '../../../Components/react/BrandGrid';
import BrandDetail from '../../../Components/react/BrandDetail';

const Marken: FC = () => {
    const {path} = useRouteMatch();

    return (
        <>
            <Route exact path={path}>
                <div className="marken-njbfnnianbne">
                    <span style={{fontSize: "xxx-large"}} className="center">Marken</span>
                    <BrandGrid details={true}/>
                </div>
            </Route>
            <Route path={`${path}/:machineName`}>
                <BrandDetail parentUrl={path}/>
            </Route>
        </>
    );
};
export default Marken;
